<template>
  <div class="login-container">
    <div class="card">
      <h2>Racing Max</h2>
      <div class="form-item">
        <span>User Name</span>
        <v-input
          v-model="username"
          placeholder="User Name"
          @keyEnter="requestLogin"/>
      </div>
      <div class="form-item">
        <span>Password</span>
        <v-input
          v-model="password"
          minlength="8"
          type="password"
          placeholder="password"
          @keyEnter="requestLogin"/>
      </div>
    </div>
    <v-button
      class="button-theme"
      @click="requestLogin">Login
    </v-button>
  </div>
</template>
<script>
import {login} from '@/api/account'

export default {
  name: 'login',
  data() {
    return {
      // test
      username: '',
      password: ''
      // Qq123456
    }
  },
  methods: {
    requestLogin() {
      this.network().login()
    },
    network() {
      return {
        login: async () => {
          const {data} = await login({
            username: this.username,
            password: this.password
          })
          localStorage.setItem('token', data.access_token)
          localStorage.setItem('auth', JSON.stringify(data))
          const {fullPath = ''} = this.$route.query
          if (fullPath) {
            this.$router.replace(fullPath)
          } else {
            this.$router.replace('/')
          }
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  .button-theme {
    margin-right: -240px;
  }

  .card {
    padding: 100px 0 0 0;
    width: 500px;

    h2 {
      text-align: center;
    }

    .form-item {
      margin: 0 auto;
      width: 300px;
      margin-bottom: 15px;

      & > span {
        text-align: left;
      }
    }

    .el-button {
      margin-right: 300px;
    }
  }
}
</style>
